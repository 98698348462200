<template>
  <div>
    <h1>{{ $t('views.academy.lessons.methods.full_house.h1_title') }}</h1>
    <p>{{ $t('views.academy.lessons.methods.full_house.p_text_1') }}</p>
    <youtube :video-id="videoId" :player-width="player.width" :player-height="player.height"></youtube>
    <div class="text-center my-4">
      <v-btn :href="hrefYoutube" color="red" rounded large class="my-2 mx-2 white--text font-weight-bold">
        <fa-icon :icon="['fab', 'youtube']" size="2x" class="mr-2" />Auf YouTube ansehen
      </v-btn>
      <v-btn :href="hrefYoutubeChannel" color="blue darken-1" rounded large class="my-2 mx-2 white--text font-weight-bold">
        <fa-icon :icon="['fa', 'bell']" size="xl" class="mr-2" /> Auf YouTube abonnieren
      </v-btn>
    </div>
    <h2>{{ $t('views.academy.lessons.methods.full_house.h2_section_1') }}</h2>
    <p>{{ $t('views.academy.lessons.methods.full_house.p_text_2') }}</p>
    <p class="text-left">
      <v-img
        max-width="300"
        :src="webpExtension(`${settings.assets.url}images/academy/full-house/image-1.jpg`)"
        :alt="$t('views.academy.lessons.methods.full_house.img_title_1')"
        :title="$t('views.academy.lessons.methods.full_house.img_title_1')"
      />
    </p>
    <div class="text-center my-3">
      <Adslot ad-unit="content-first" ad-class="adslot__content-first" />
    </div>
    <h2>{{ $t('views.academy.lessons.methods.full_house.h2_section_2') }}</h2>
    <p>{{ $t('views.academy.lessons.methods.full_house.p_text_3') }}</p>
    <p class="text-left">
      <v-img
        max-width="300"
        :src="webpExtension(`${settings.assets.url}images/academy/full-house/image-2.jpg`)"
        :alt="$t('views.academy.lessons.methods.full_house.img_title_2')"
        :title="$t('views.academy.lessons.methods.full_house.img_title_2')"
      />
    </p>
    <h2>{{ $t('views.academy.lessons.methods.full_house.h2_section_3') }}</h2>
    <p>{{ $t('views.academy.lessons.methods.full_house.p_text_4') }}</p>
    <div class="text-center my-3">
      <Adslot ad-unit="content-second" ad-class="adslot__content-second" />
    </div>
    <h2>{{ $t('views.academy.lessons.methods.full_house.h2_section_4') }}</h2>
    <p>{{ $t('views.academy.lessons.methods.full_house.p_text_5') }}</p>
    <div class="text-center my-3">
      <Adslot ad-unit="content-last" ad-class="adslot__content-last" />
    </div>
    <div class="text-center">
      <btn-home class="my-3"></btn-home>
    </div>
  </div>
</template>

<script>
import Adslot from '@/components/Adslot.vue'
import { webp } from '@/mixins'
import { settings } from '@/config'
import BtnHome from '@/components/btn/Home.vue'

export default {
  name: 'FullHouse',
  components: { Adslot, BtnHome },
  mixins: [webp],
  data() {
    return {
      videoId: 'JGtGLDX_y1A',
      hrefYoutube: 'https://www.youtube.com/watch?v=JGtGLDX_y1A',
      hrefYoutubeChannel: 'https://www.youtube.com/channel/UCfjJQCOWt1YBjw6LPmSCpCQ?sub_confirmation=1',
    }
  },
  computed: {
    settings() {
      return settings
    },
    player() {
      if (this.breakpoint === 'xs')
        return {
          width: 266,
          height: 150,
        }
      if (this.breakpoint === 'sm')
        return {
          width: '520',
          height: '293',
        }
      if (this.breakpoint === 'md')
        return {
          width: '432',
          height: '243',
        }
      return {
        width: 694,
        height: 390,
      }
    },
  },
}
</script>

<style lang="scss"></style>
