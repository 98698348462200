<template>
  <v-btn :color="$theme.btn.color" outlined rounded :href="href" :aria-label="ariaLabel" @click.prevent="go()">
    <fa-icon class="mr-2" size="lg" :icon="['fad', 'home-heart']" />
    {{ $t('hyperlinks.home.purpose') }}
  </v-btn>
</template>

<script>
import { mapActions } from 'vuex'
import { theme } from '@/mixins'

export default {
  name: 'BtnHome',
  mixins: [theme],
  data() {
    return {
      href: this.$t('hyperlinks.home.href'),
      ariaLabel: this.$t('hyperlinks.home.purpose'),
      target: '_self',
    }
  },
  methods: {
    ...mapActions(['showLoader', 'hideLoader', 'hideNavigation', 'setPurpose']),
    go() {
      this.hideNavigation()
      this.setPurpose(this.ariaLabel)
      this.showLoader()
      setTimeout(() => {
        window.open(this.href, this.target).then(() => this.hideLoader())
      }, 1)
    },
  },
}
</script>

<style lang="scss"></style>
