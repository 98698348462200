<template>
  <div>
    <h1>{{ $t('views.academy.lessons.methods.hidden_single.h1_title') }}</h1>
    <p>{{ $t('views.academy.lessons.methods.hidden_single.p_text_1') }}</p>
    <youtube :video-id="videoId" :player-width="player.width" :player-height="player.height"></youtube>
    <div class="text-center my-4">
      <v-btn :href="hrefYoutube" color="red" rounded large class="my-2 mx-2 white--text font-weight-bold">
        <fa-icon :icon="['fab', 'youtube']" size="2x" class="mr-2" />Auf YouTube ansehen
      </v-btn>
      <v-btn :href="hrefYoutubeChannel" color="blue darken-1" rounded large class="my-2 mx-2 white--text font-weight-bold">
        <fa-icon :icon="['fa', 'bell']" size="xl" class="mr-2" /> Auf YouTube abonnieren
      </v-btn>
    </div>
    <div class="text-center my-3">
      <Adslot ad-unit="content-first" ad-class="adslot__content-first" />
    </div>
    <h2>{{ $t('views.academy.lessons.methods.hidden_single.h2_section_2') }}</h2>
    <p>{{ $t('views.academy.lessons.methods.hidden_single.p_text_3') }}</p>
    <h2>{{ $t('views.academy.lessons.methods.hidden_single.h2_section_3') }}</h2>
    <p>{{ $t('views.academy.lessons.methods.hidden_single.p_text_4') }}</p>
    <v-row class="mb-6">
      <v-col>
        <v-img
          max-width="300"
          :src="webpExtension(`${settings.assets.url}images/academy/hidden-single/image-1.jpg`)"
          :alt="$t('views.academy.lessons.methods.hidden_single.img_title_1')"
          :title="$t('views.academy.lessons.methods.hidden_single.img_title_1')"
        />
      </v-col>
      <v-col>
        <v-img
          max-width="300"
          :src="webpExtension(`${settings.assets.url}images/academy/hidden-single/image-2.jpg`)"
          :alt="$t('views.academy.lessons.methods.hidden_single.img_title_2')"
          :title="$t('views.academy.lessons.methods.hidden_single.img_title_2')"
        />
      </v-col>
    </v-row>
    <p>{{ $t('views.academy.lessons.methods.hidden_single.p_text_5') }}</p>
    <v-row class="mb-6">
      <v-col>
        <v-img
          max-width="300"
          :src="webpExtension(`${settings.assets.url}images/academy/hidden-single/image-3.jpg`)"
          :alt="$t('views.academy.lessons.methods.hidden_single.img_title_3')"
          :title="$t('views.academy.lessons.methods.hidden_single.img_title_3')"
        />
      </v-col>
      <v-col>
        <v-img
          max-width="300"
          :src="webpExtension(`${settings.assets.url}images/academy/hidden-single/image-4.jpg`)"
          :alt="$t('views.academy.lessons.methods.hidden_single.img_title_4')"
          :title="$t('views.academy.lessons.methods.hidden_single.img_title_4')"
        />
      </v-col>
    </v-row>
    <p>{{ $t('views.academy.lessons.methods.hidden_single.p_text_6') }}</p>
    <v-row class="mb-6">
      <v-col>
        <v-img
          max-width="300"
          :src="webpExtension(`${settings.assets.url}images/academy/hidden-single/image-5.jpg`)"
          :alt="$t('views.academy.lessons.methods.hidden_single.img_title_5')"
          :title="$t('views.academy.lessons.methods.hidden_single.img_title_5')"
        />
      </v-col>
    </v-row>
    <div class="text-center my-3">
      <Adslot ad-unit="content-second" ad-class="adslot__content-second" />
    </div>
    <h2>{{ $t('views.academy.lessons.methods.hidden_single.h2_section_4') }}</h2>
    <p>{{ $t('views.academy.lessons.methods.hidden_single.p_text_7') }}</p>
    <v-row class="mb-6">
      <v-col>
        <v-img
          max-width="300"
          :src="webpExtension(`${settings.assets.url}images/academy/hidden-single/image-6.jpg`)"
          :alt="$t('views.academy.lessons.methods.hidden_single.img_title_6')"
          :title="$t('views.academy.lessons.methods.hidden_single.img_title_6')"
        />
      </v-col>
    </v-row>
    <p>{{ $t('views.academy.lessons.methods.hidden_single.p_text_8') }}</p>
    <v-row class="mb-6">
      <v-col>
        <v-img
          max-width="300"
          :src="webpExtension(`${settings.assets.url}images/academy/hidden-single/image-7.jpg`)"
          :alt="$t('views.academy.lessons.methods.hidden_single.img_title_7')"
          :title="$t('views.academy.lessons.methods.hidden_single.img_title_7')"
        />
      </v-col>
    </v-row>
    <p>{{ $t('views.academy.lessons.methods.hidden_single.p_text_9') }}</p>
    <v-row class="mb-6">
      <v-col>
        <v-img
          max-width="300"
          :src="webpExtension(`${settings.assets.url}images/academy/hidden-single/image-8.jpg`)"
          :alt="$t('views.academy.lessons.methods.hidden_single.img_title_8')"
          :title="$t('views.academy.lessons.methods.hidden_single.img_title_8')"
        />
      </v-col>
    </v-row>
    <div class="text-center my-3">
      <Adslot ad-unit="content-third" ad-class="adslot__content-third" />
    </div>
    <h2>{{ $t('views.academy.lessons.methods.hidden_single.h2_section_5') }}</h2>
    <p>{{ $t('views.academy.lessons.methods.hidden_single.p_text_10') }}</p>
    <p>{{ $t('views.academy.lessons.methods.hidden_single.p_text_11') }}</p>
    <v-row class="mb-6">
      <v-col>
        <v-img
          max-width="300"
          :src="webpExtension(`${settings.assets.url}images/academy/hidden-single/image-9.jpg`)"
          :alt="$t('views.academy.lessons.methods.hidden_single.img_title_9')"
          :title="$t('views.academy.lessons.methods.hidden_single.img_title_9')"
        />
      </v-col>
    </v-row>
    <p>{{ $t('views.academy.lessons.methods.hidden_single.p_text_12') }}</p>
    <h2>{{ $t('views.academy.lessons.methods.hidden_single.h2_section_6') }}</h2>
    <p>{{ $t('views.academy.lessons.methods.hidden_single.p_text_14') }}</p>
    <div class="text-center my-3">
      <Adslot ad-unit="content-last" ad-class="adslot__content-last" />
    </div>
    <div class="text-center">
      <btn-home class="my-3"></btn-home>
    </div>
  </div>
</template>

<script>
import Adslot from '@/components/Adslot.vue'
import { webp } from '@/mixins'
import { settings } from '@/config'
import BtnHome from '@/components/btn/Home.vue'

export default {
  name: 'HiddenSingle',
  components: { Adslot, BtnHome },
  mixins: [webp],
  data() {
    return {
      videoId: 'VoXDG2-ZWt0',
      hrefYoutube: 'https://www.youtube.com/watch?v=VoXDG2-ZWt0',
      hrefYoutubeChannel: 'https://www.youtube.com/channel/UCfjJQCOWt1YBjw6LPmSCpCQ?sub_confirmation=1',
    }
  },
  computed: {
    settings() {
      return settings
    },
    player() {
      if (this.breakpoint === 'xs')
        return {
          width: 266,
          height: 150,
        }
      if (this.breakpoint === 'sm')
        return {
          width: '520',
          height: '293',
        }
      if (this.breakpoint === 'md')
        return {
          width: '432',
          height: '243',
        }
      return {
        width: 694,
        height: 390,
      }
    },
  },
}
</script>

<style lang="scss"></style>
