<template>
  <div>
    <h1><fa-icon :icon="['fal', 'graduation-cap']" /> {{ $t('views.academy.h1_title') }}</h1>
    <h2>{{ $t('views.academy.h2_subtitle') }}</h2>
    <v-divider class="mb-12" />
    <p>{{ $t('views.academy.p_text_1') }}</p>
    <h1>{{ $t('views.academy.h1_methods') }}</h1>
    <div class="text-center my-3">
      <Adslot ad-unit="content-first" ad-class="adslot__content-first" />
    </div>
    <div class="cards-container" :style="[$vuetify.breakpoint.xsOnly ? 'justify-content: center;' : '']">
      <!-- Flex Container -->

      <v-card v-for="(card, index) in methodCards" :key="`card-${index}`" :disabled="card.disabled" class="card" max-width="320">
        <!-- Einzelne Karte -->
        <v-img
          class="white--text align-end"
          height="180px"
          :src="
            card.disabled
              ? webpExtension(`${settings.assets.url}images/academy/cards/disabled.jpg`)
              : webpExtension(`${settings.assets.url}images/academy/${card.method}/title-screen-320x180.jpg`)
          "
        >
        </v-img>
        <v-card-title class="pb-0">{{ card.title }}</v-card-title>
        <v-card-text class="text--primary">
          <div class="academy__card__text">
            {{ card.description }}
          </div>
        </v-card-text>
        <v-card-actions class="justify-center mb-3">
          <v-btn :href="card.href" outlined rounded color="blue-grey accent-4">
            <span class="mx-2">{{ $t('views.academy.btn_open') }}</span>
          </v-btn>
        </v-card-actions>
        <v-divider class="mx-4"></v-divider>
        <v-row no-gutters class="">
          <v-col class="flex-grow-1">
            <v-card-text class="text--primary">
              <div><fa-icon size="xs" :icon="['far', 'clock']" class="mr-2" />{{ card.duration }}</div>
              <div><fa-icon size="xs" :icon="['far', 'star']" class="mr-2" />{{ card.level }}</div>
            </v-card-text>
          </v-col>
          <v-col class="flex-grow-1 mt-2">
            <v-list-item class="grow">
              <v-list-item-content>
                <v-list-item-title class="text-right"
                  >{{ card.avatar.name }}<fa-icon v-if="card.avatar.ai" size="sm" :icon="['far', 'microchip-ai']" class="ml-1"
                /></v-list-item-title>
              </v-list-item-content>
              <v-list-item-avatar color="grey darken-3">
                <v-img
                  class="elevation-6"
                  alt=""
                  :src="webpExtension(`${settings.assets.url}images/academy/avatars/${card.avatar.image}`)"
                ></v-img>
              </v-list-item-avatar>
            </v-list-item>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <div class="text-center my-3">
      <Adslot ad-unit="content-last" ad-class="adslot__content-last" />
    </div>
    <div class="text-center">
      <btn-home class="my-3"></btn-home>
    </div>
  </div>
</template>

<script>
import Adslot from '../../components/Adslot.vue'
import { settings } from '@/config'
import { webp } from '@/mixins'
import BtnHome from '@/components/btn/Home.vue'

export default {
  name: 'Academy',
  components: { Adslot, BtnHome },
  mixins: [webp],
  inject: ['theme'],
  data() {
    return {
      methodCards: [
        {
          disabled: false,
          method: 'full-house',
          href: this.$t('hyperlinks.academy.lessons.methods.full_house.href'),
          title: this.$t('views.academy.lessons.methods.full_house.h1_title'),
          description: this.$t('views.academy.lessons.methods.full_house.p_text_1'),
          duration: this.$t('views.academy.lessons.methods.full_house.list_duration'),
          level: this.$t('views.academy.lessons.methods.full_house.list_level'),
          avatar: {
            name: 'Kim',
            ai: true,
            image: 'kim.jpg',
          },
        },
        {
          disabled: false,
          method: 'naked-single',
          href: this.$t('hyperlinks.academy.lessons.methods.naked_single.href'),
          title: this.$t('views.academy.lessons.methods.naked_single.h1_title'),
          description: this.$t('views.academy.lessons.methods.naked_single.p_text_1'),
          avatar: {
            name: 'Kian',
            ai: true,
            image: 'kian.jpg',
          },
          duration: this.$t('views.academy.lessons.methods.naked_single.list_duration'),
          level: this.$t('views.academy.lessons.methods.naked_single.list_level'),
        },
        {
          disabled: false,
          method: 'hidden-single',
          href: this.$t('hyperlinks.academy.lessons.methods.hidden_single.href'),
          title: this.$t('views.academy.lessons.methods.hidden_single.h1_title'),
          description: this.$t('views.academy.lessons.methods.hidden_single.p_text_1'),
          duration: this.$t('views.academy.lessons.methods.hidden_single.list_duration'),
          level: this.$t('views.academy.lessons.methods.hidden_single.list_level'),
          avatar: {
            name: 'Kian',
            ai: true,
            image: 'kian.jpg',
          },
        },
        {
          disabled: true,
          method: 'locked-candidates-part-1',
          title: this.$t('views.academy.lessons.methods.locked_candidates_part_1.h1_title'),
          description: this.$t('views.academy.p_planned'),
          duration: this.$t('views.academy.lessons.methods.locked_candidates_part_1.list_duration'),
          level: this.$t('views.academy.lessons.methods.locked_candidates_part_1.list_level'),
          avatar: {
            name: 'Kim',
            ai: true,
            image: 'kim.jpg',
          },
        },
        {
          disabled: true,
          method: 'locked-candidates-part-2',
          title: this.$t('views.academy.lessons.methods.locked_candidates_part_2.h1_title'),
          description: this.$t('views.academy.p_planned'),
          duration: this.$t('views.academy.lessons.methods.locked_candidates_part_2.list_duration'),
          level: this.$t('views.academy.lessons.methods.locked_candidates_part_2.list_level'),
          avatar: {
            name: 'Kim',
            ai: true,
            image: 'kim.jpg',
          },
        },
      ],
    }
  },
  computed: {
    settings() {
      return settings
    },
  },
}
</script>

<style lang="scss">
.academy {
  &__card__text {
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    height: 65px;
    overflow: hidden;
  }
}

.cards-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.card {
  margin: 10px;
}
</style>
